var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/sentry/tunnel";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"59429a0bfedf6f3abf612a88ec0d0fdd5df9f07b"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { browserTracingIntegration, init, replayIntegration } from '@sentry/nextjs';

import { config } from '@endaoment-frontend/config';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

init({
  dsn: SENTRY_DSN || 'https://d8c6a78c78e14720b3ebb5698aebf500@o1285899.ingest.sentry.io/6528015',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,

  integrations: [
    browserTracingIntegration(),
    replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [config.baseUrls.api],
    }),
  ],

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: process.env.NEXT_PUBLIC_ENV,

  // If tracePropagationTargets is not provided, trace data is attached to every outgoing request from the instrumented client.
  tracePropagationTargets: undefined,

  ignoreErrors: [
    /Loading chunk \d+ failed/,
    /Loading CSS chunk \d+ failed/,
    'Invariant: attempted to hard navigate to the same URL',
  ],
});
